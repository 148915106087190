<template>
  <div>
    <!-- begin:: Section Report -->
    <ReportSection class="mb-6"></ReportSection>
    <!-- end:: Section Report -->

    <!-- begin:: Section Transaction History -->
    <TransactionHistorySection></TransactionHistorySection>
    <!-- end:: Section Transaction History -->
  </div>
</template>

<script>
  import TransactionHistorySection from '@/views/components/payment/transaction-history/TransactionHistorySection';
  import ReportSection from '@/views/components/payment/report/ReportSection';
  import { ROUTE_NAME } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'PaymentDashboard',
    components: {
      ReportSection,
      TransactionHistorySection
    },
    mixins: [commonMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.banner'),
            route: { name: ROUTE_NAME.BANNER }
          },
          { title: i18nHelper.getMessage('label.bannerList') }
        ]
      }
    }),

    created() {
      this.initPaymentDashboard();
    },
    methods: {
      initPaymentDashboard() {
        const breadcrumbData = {
          breadcrumbs: [
            {
              title: i18nHelper.getMessage('label.payment')
            }
          ]
        };

        this.initBreadCrumb(breadcrumbData);
      }
    }
  };
</script>
