<template>
  <section>
    <!-- begin:: Report Filter Panel -->
    <b-card class="mb-6">
      <b-card-title>
        <h2>{{ $t('label.analyticsAndFinanceReport') }}</h2>
      </b-card-title>

      <b-row no-gutters>
        <template v-for="(i, index) in filters">
          <b-col :key="index" cols="12" md="4" xl="2">
            <label class="font-size-sm"
              >{{ $t(`label.${i.label}`) }}:
              <span
                v-if="i.tooltip"
                v-b-tooltip.hover
                :title="i.tooltip"
                class="text-primary font-weight-bold"
                >[?]</span
              ></label
            >
            <AppSmartFormField
              v-model="i.value"
              :formId="index"
              :type="i.type"
              :minDate="i.minDate"
              :maxDate="i.maxDate"
            ></AppSmartFormField>
          </b-col>
        </template>

        <b-col class="d-flex justify-content-end align-items-center">
          <b-button
            type="submit"
            variant="primary"
            class="max-h-40px"
            size="sm"
            @click="onClickSearch"
          >
            <i class="flaticon-search p-0 mr-2"></i>
            {{ $t('label.search') }}</b-button
          >
        </b-col>
      </b-row>
    </b-card>
    <!-- end:: Report Filter Panel -->

    <!-- begin:: Reports -->
    <b-row no-gutters>
      <b-col cols="12" md="6" class="pr-md-4 mb-6 mb-md-0">
        <ReportTotalMonthlySalesChartCard
          :value="reportObj"
        ></ReportTotalMonthlySalesChartCard>
      </b-col>
      <b-col cols="12" md="6" class="pl-md-4">
        <ReportAgeRangeChartCard :value="reportObj"></ReportAgeRangeChartCard>
      </b-col>
      <b-col cols="12" md="6" class="pr-md-4 pt-md-5">
        <ReportTotalMonthlyTalentSalesChartCard
          :value="reportObj"
        ></ReportTotalMonthlyTalentSalesChartCard>
      </b-col>
      <b-col cols="12" md="6" class="pl-md-4 pt-md-5">
        <ReportTotalMonthlyProductionSalesChartCard
          :value="reportObj"
        ></ReportTotalMonthlyProductionSalesChartCard>
      </b-col>
    </b-row>
    <!-- end:: Reports -->
  </section>
</template>

<script>
  import moment from 'moment';
  import AppSmartFormField from '@/views/components/bases/smart/AppSmartFormField';
  import ReportTotalMonthlySalesChartCard from '@/views/components/payment/report/ReportTotalMonthlySalesChartCard';
  import ReportAgeRangeChartCard from '@/views/components/payment/report/ReportAgeRangeChartCard';
  import ReportTotalMonthlyTalentSalesChartCard from '@/views/components/payment/report/ReportTotalMonthlyTalentSalesChartCard';
  import ReportTotalMonthlyProductionSalesChartCard from '@/views/components/payment/report/ReportTotalMonthlyProductionSalesChartCard';
  import { ROUTE_NAME, SMART_FORM_FIELD_TYPE, GLOBAL } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import commonMixin from '@/core/mixins/common.mixin';
  import {
    PAYMENT_GET_REPORT,
    PAYMENT_INITIAL_GET_REPORT_STATE
  } from '@/core/store/payment.module';

  export default {
    name: 'ReportSection',
    components: {
      AppSmartFormField,
      ReportTotalMonthlySalesChartCard,
      ReportAgeRangeChartCard,
      ReportTotalMonthlyTalentSalesChartCard,
      ReportTotalMonthlyProductionSalesChartCard
    },
    mixins: [commonMixin],
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.banner'),
            route: { name: ROUTE_NAME.BANNER }
          },
          { title: i18nHelper.getMessage('label.bannerList') }
        ]
      },
      filters: [
        {
          label: 'monthRange',
          key: 'monthDateRange',
          value: {
            start: moment(GLOBAL.FIRST_DATE_OF_CURRENT_YEAR).format(
              'YYYY-MM-DD'
            ),
            shortcut: null,
            end: moment(new Date()).format('YYYY-MM-DD')
          },
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE,
          maxDate: moment(new Date()).format('YYYY-MM-DD')
        }
      ],
      reportObj: null
    }),
    computed: {
      reportComplete() {
        return this.$store.state.payment.report.complete;
      }
    },
    watch: {
      reportComplete() {
        var response = this.$store.state.payment.report;
        var title = 'getReport';
        var initialStateAction = PAYMENT_INITIAL_GET_REPORT_STATE;
        var successAction = (response) => {
          this.getReportSucceedAction(response);
        };

        if (response.complete)
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
      }
    },
    created() {
      this.initReportSection();
    },
    methods: {
      monthDateRangeDataMapping(dateRange) {
        return [dateRange['start'], dateRange['end']].toString();
      },
      onClickSearch() {
        var data = this.mapArrayToObjectData(this.filters);

        if (data.monthDateRange) {
          data.monthDateRange = this.monthDateRangeDataMapping(
            data.monthDateRange
          );
        }

        this.getReport(data);
      },
      getReportSucceedAction(response) {
        var data = response.data;

        this.reportObj = data;
      },
      getReport(data) {
        this.$store.dispatch(PAYMENT_GET_REPORT, { data });
      },
      initReportSection() {
        var data = this.mapArrayToObjectData(this.filters);

        if (data.monthDateRange) {
          data.monthDateRange = this.monthDateRangeDataMapping(
            data.monthDateRange
          );
        }

        this.getReport(data);
      }
    }
  };
</script>

<style lang="scss"></style>
