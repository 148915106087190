<template>
  <section>
    <AppSmartList
      no-breadcrumb
      :title="$t('label.transactionHistory')"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </section>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import { MODULE, ACTION, SMART_FORM_FIELD_TYPE } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import {
    PAYMENT_GET_TRANSACTION_HISTORY,
    PAYMENT_EXPORT_TRANSACTION_HISTORY_TO_EXCEL,
    PAYMENT_INITIAL_GET_TRANSACTION_HISTORY_STATE,
    PAYMENT_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE
  } from '@/core/store/payment.module';
  import { listService } from '@/core/services';

  export default {
    name: 'TransactionHistorySection',
    components: {
      AppSmartList
    },
    data: () => ({
      accessRights: {
        moduleName: MODULE.PAYMENT,
        actions: {
          read: ACTION.PAYMENT_READ
        }
      },
      filters: [
        {
          label: 'referenceId',
          key: 'referenceId',
          value: ''
        },
        {
          label: 'userName',
          key: 'userName',
          value: ''
        },
        {
          label: 'type',
          key: 'type',
          value: '',
          options: listService.getSubscriptionTypeList(),
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'paidDateRange',
          key: 'paidDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        }
      ],
      actions: {
        viewList: {
          module: 'payment',
          state: 'transactionHistory',
          action: PAYMENT_GET_TRANSACTION_HISTORY,
          initialStateAction: PAYMENT_INITIAL_GET_TRANSACTION_HISTORY_STATE,
          title: i18nHelper.getMessage('label.getTransactionHistory')
        },
        exportExcel: {
          module: 'payment',
          state: 'exportTransactionHistoryToExcel',
          action: PAYMENT_EXPORT_TRANSACTION_HISTORY_TO_EXCEL,
          initialStateAction:
            PAYMENT_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE,
          title: i18nHelper.getMessage('label.exportExcel')
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'referenceId',
          name: 'referenceId',
          active: true,
          sortable: true
        },
        {
          key: 'userName',
          name: 'userName',
          active: true,
          sortable: true
        },
        {
          key: 'type',
          name: 'type',
          active: true,
          sortable: true
        },
        {
          key: 'paymentMethod',
          name: 'paymentMethod',
          active: true,
          sortable: true
        },
        {
          key: 'amount',
          name: 'amount',
          active: true,
          sortable: true
        },
        {
          key: 'subscriptionPeriod',
          name: 'subscriptionPeriod',
          active: true,
          sortable: true
        },
        {
          key: 'paidAt',
          name: 'paidAt',
          active: true,
          sortable: true
        }
      ]
    })
  };
</script>

<style></style>
