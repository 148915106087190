<template>
  <b-card class="h-100">
    <template #header>
      <h3>
        {{ $t('label.totalMonthlySalesTalent') }}
      </h3>
    </template>

    <apexchart v-if="value" :options="options" :series="series"></apexchart>

    <div v-else class="text-center p-2">
      <span>{{ $t('message.thereAreNoDataToShow') }}</span>
    </div>
  </b-card>
</template>

<script>
  export default {
    name: 'ReportTotalMonthlyTalentSalesChartCard',
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      options: {
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: true,
            tools: {
              download: true
            },
            export: {
              csv: {
                filename:
                  new Date().toISOString() + '_Total_Monthly_Talent_Sales',
                columnDelimiter: ',',
                headerCategory: 'Month',
                headerValue: 'Sales'
              },
              svg: {
                filename:
                  new Date().toISOString() + '_Total_Monthly_Talent_Sales'
              },
              png: {
                filename:
                  new Date().toISOString() + '_Total_Monthly_Talent_Sales'
              }
            }
          }
        }
      },
      series: []
    }),
    watch: {
      value(newValue) {
        if (newValue) this.importReportData(newValue);
      }
    },
    created() {
      this.importReportData(this.value);
    },
    methods: {
      importReportData(data) {
        if (data) {
          this.series = [];
          console.log(data);
          this.series.push({
            name: 'Total Sale (Talent)',
            data: (data?.subscription_month_sales?.talent || []).map((sale) => {
              return {
                x: sale.month,
                y: sale.sales
              };
            })
          });
        }
      }
    }
  };
</script>

<style></style>
