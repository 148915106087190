<template>
  <b-card>
    <template #header>
      <h3>
        {{ $t('label.ageRange') }}
      </h3>
    </template>

    <apexchart v-if="value" :options="options" :series="series"></apexchart>

    <div v-else class="text-center p-2">
      <span>{{ $t('message.thereAreNoDataToShow') }}</span>
    </div>
  </b-card>
</template>

<script>
  export default {
    name: 'ReportAgeRangeChartCard',
    props: {
      value: {
        type: Object,
        default: () => {}
      }
    },
    data: () => ({
      options: {
        chart: {
          height: 350,
          type: 'pie',
          toolbar: {
            show: true,
            tools: {
              download: true
            },
            export: {
              csv: {
                filename: new Date().toISOString() + '_Age_Range',
                columnDelimiter: ',',
                headerCategory: 'Age Range',
                headerValue: 'Volume'
              },
              svg: {
                filename: new Date().toISOString() + '_Age_Range'
              },
              png: {
                filename: new Date().toISOString() + '_Age_Range'
              }
            }
          }
        },
        labels: []
        // responsive: [
        //   {
        //     breakpoint: 1920,
        //     options: {
        //       chart: {
        //         height: 850
        //       }
        //     }
        //   }
        // ]
      },
      series: []
    }),
    watch: {
      value(newValue) {
        if (newValue) this.importReportData(newValue);
      }
    },
    created() {
      this.importReportData(this.value);
    },
    methods: {
      importReportData(data) {
        if (data) {
          this.series = [];
          this.options.labels = [];

          data.ageRange.forEach((range) => {
            this.series.push(range.count);
            this.options.labels.push(range.ageRange);
          });
        }
      }
    }
  };
</script>

<style></style>
